/**
 * Toast styling utility that provides consistent class and style management
 * following PrimeVue's pattern. This utility generates the necessary classes
 * and inline styles for positioning and appearance of toast components.
 */

// Inline styles generator for toast positioning
const inlineStyles = {
  root: (position: string) => ({
    position: "fixed",
    top:
      position === "top-right" ||
        position === "top-left" ||
        position === "top-center"
        ? "20px"
        : position === "center"
          ? "50%"
          : undefined,
    right:
      position === "top-right" || position === "bottom-right"
        ? "20px"
        : undefined,
    bottom:
      position === "bottom-left" ||
        position === "bottom-right" ||
        position === "bottom-center"
        ? "20px"
        : undefined,
    left:
      position === "top-left" || position === "bottom-left"
        ? "20px"
        : position === "center" ||
          position === "top-center" ||
          position === "bottom-center"
          ? "50%"
          : undefined,
  }),
};

// Class generators for toast components
const classes = {
  root: (position: string) => [
    "z-[9999]",
    "p-toast",
    "p-component",
    `p-toast-${position}`,
  ],

  message: (severity?: string) => [
    "p-toast-message",
    {
      "p-toast-message-info": severity === "info" || severity === undefined,
      "p-toast-message-warn": severity === "warn",
      "p-toast-message-error": severity === "error",
      "p-toast-message-success": severity === "success",
      "p-toast-message-secondary": severity === "secondary",
      "p-toast-message-contrast": severity === "contrast",
    },
  ],

  content: () => ["p-toast-message-content"],

  icon: (severity?: string, icons = {}) => [
    "p-toast-message-icon",
    {
      [icons.infoIcon || ""]: severity === "info",
      [icons.warnIcon || ""]: severity === "warn",
      [icons.errorIcon || ""]: severity === "error",
      [icons.successIcon || ""]: severity === "success",
    },
  ],

  text: () => ["p-toast-message-text"],
  summary: () => ["p-toast-summary"],
  detail: () => ["p-toast-detail"],
  closeButton: () => ["p-toast-close-button"],
  closeIcon: () => ["p-icon p-toast-close-icon"],
};

export const ToastStyle = {
  inlineStyles,
  classes,
};
