<template>
  <ConfigProvider>
    <ToastWrapper>
      <NuxtLoadingIndicator />
      <DynamicDialog />
      <NuxtLayout>
        <template #header v-if="page?.pageRef?.headerTitle">
          <div class="bg-white shadow dark:bg-surface-700">
            <div class="py-2 px-6 max-w-full">
              <div
                class="flex flex-wrap justify-between items-center sm:flex-nowrap"
              >
                <h2
                  v-text="page?.pageRef?.headerTitle"
                  class="py-2 mr-auto text-xl font-semibold leading-tight text-gray-800"
                ></h2>
                <div class="flex-shrink-0 mt-2 ml-4">
                  <button
                    type="button"
                    class="inline-flex items-center py-2 px-3 text-sm font-medium leading-4 text-gray-500 rounded-md border border-transparent transition dark:text-gray-200 hover:text-gray-700 focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      class="w-6 h-6"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <NuxtPage ref="page" />
      </NuxtLayout>
    </ToastWrapper>
  </ConfigProvider>
</template>
<script setup>
import * as string from "@/utils/string";
const page = ref();
</script>
