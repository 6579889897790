<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import type { ToastMessageType } from "~/types/Toast";
import { hasGroup } from "~/types/Toast";
import ToastMessage from "./ToastMessage.vue";
import { ToastStyle } from "./toastStyle";

const props = withDefaults(
  defineProps<{
    position?:
      | "top-right"
      | "top-left"
      | "bottom-right"
      | "bottom-left"
      | "top-center"
      | "bottom-center";
    group?: string | undefined;
  }>(),
  {
    position: "top-right",
    group: undefined,
  },
);

let messageIdx = 0;
const messages = ref<ToastMessageType[]>([]);
const { $eventBus } = useNuxtApp();
const rootStyles = computed(() => ToastStyle.inlineStyles.root(props.position));
const rootClasses = computed(() => ToastStyle.classes.root(props.position));
onMounted(() => {
  $eventBus.on("toast", handleToast);
});

onBeforeUnmount(() => {
  $eventBus.off("toast", handleToast);
});

function handleToast(message: ToastMessageType) {
  try {
    if (hasGroup(message) && message.unique) {
      removeGroup(message.group);
    }
    if (!props.group || message.group === props.group) {
      addMessage({
        ...message,
        id: messageIdx++,
        ...(message?.life && { life: message.life }),
        isOpen: true,
      });
    }
  } catch (error) {
    console.error("Error handling toast:", error);
  }
}

function addMessage(message: ToastMessageType) {
  messages.value.push(message);
}

function removeMessage(message: ToastMessageType) {
  const index = messages.value.findIndex((m) => m.id === message.id);
  if (index !== -1) {
    messages.value.splice(index, 1);
  }
}

function removeGroup(group: string) {
  messages.value = messages.value.filter((m) => m.group !== group);
}

function onClose({
  message,
  type,
}: {
  message: ToastMessageType;
  type: string;
}) {
  removeMessage(message);
}
</script>

<template>
  <ToastProvider>
    <slot />
    <ToastMessage
      v-for="message of messages"
      :key="message.id"
      :message="message"
      @close="onClose"
      :open="message?.isOpen ?? true"
    />
    <ToastViewport :class="rootClasses" :style="rootStyles" :hotkey="['F8']" />
  </ToastProvider>
</template>
<style>
:root {
  --p-toast-width: 25rem;
  --p-toast-border-radius: var(--p-content-border-radius);
  --p-toast-border-width: 1px;
  --p-toast-transition-duration: var(--p-transition-duration);
  --p-toast-icon-size: 1.125rem;
  --p-toast-content-padding: var(--p-overlay-popover-padding);
  --p-toast-content-gap: 0.5rem;
  --p-toast-text-gap: 0.5rem;
  --p-toast-summary-font-weight: 500;
  --p-toast-summary-font-size: 1rem;
  --p-toast-detail-font-weight: 500;
  --p-toast-detail-font-size: 0.875rem;
  --p-toast-close-button-width: 1.75rem;
  --p-toast-close-button-height: 1.75rem;
  --p-toast-close-button-border-radius: 50%;
  --p-toast-close-button-focus-ring-width: var(--p-focus-ring-width);
  --p-toast-close-button-focus-ring-style: var(--p-focus-ring-style);
  --p-toast-close-button-focus-ring-offset: var(--p-focus-ring-offset);
  --p-toast-close-icon-size: 1rem;
  --p-toast-blur: 1.5px;
  --p-toast-info-background: color-mix(
    in srgb,
    var(--p-blue-50),
    transparent 5%
  );
  --p-toast-info-border-color: var(--p-blue-200);
  --p-toast-info-color: var(--p-blue-600);
  --p-toast-info-detail-color: var(--p-surface-700);
  --p-toast-info-shadow: 0px 4px 8px 0px
    color-mix(in srgb, var(--p-blue-500), transparent 96%);
  --p-toast-info-close-button-hover-background: var(--p-blue-100);
  --p-toast-info-close-button-focus-ring-color: var(--p-blue-600);
  --p-toast-info-close-button-focus-ring-shadow: none;
  --p-toast-success-background: color-mix(
    in srgb,
    var(--p-green-50),
    transparent 5%
  );
  --p-toast-success-border-color: var(--p-green-200);
  --p-toast-success-color: var(--p-green-600);
  --p-toast-success-detail-color: var(--p-surface-700);
  --p-toast-success-shadow: 0px 4px 8px 0px
    color-mix(in srgb, var(--p-green-500), transparent 96%);
  --p-toast-success-close-button-hover-background: var(--p-green-100);
  --p-toast-success-close-button-focus-ring-color: var(--p-green-600);
  --p-toast-success-close-button-focus-ring-shadow: none;
  --p-toast-warn-background: color-mix(
    in srgb,
    var(--p-yellow-50),
    transparent 5%
  );
  --p-toast-warn-border-color: var(--p-yellow-200);
  --p-toast-warn-color: var(--p-yellow-600);
  --p-toast-warn-detail-color: var(--p-surface-700);
  --p-toast-warn-shadow: 0px 4px 8px 0px
    color-mix(in srgb, var(--p-yellow-500), transparent 96%);
  --p-toast-warn-close-button-hover-background: var(--p-yellow-100);
  --p-toast-warn-close-button-focus-ring-color: var(--p-yellow-600);
  --p-toast-warn-close-button-focus-ring-shadow: none;
  --p-toast-error-background: color-mix(
    in srgb,
    var(--p-red-50),
    transparent 5%
  );
  --p-toast-error-border-color: var(--p-red-200);
  --p-toast-error-color: var(--p-red-600);
  --p-toast-error-detail-color: var(--p-surface-700);
  --p-toast-error-shadow: 0px 4px 8px 0px
    color-mix(in srgb, var(--p-red-500), transparent 96%);
  --p-toast-error-close-button-hover-background: var(--p-red-100);
  --p-toast-error-close-button-focus-ring-color: var(--p-red-600);
  --p-toast-error-close-button-focus-ring-shadow: none;
  --p-toast-secondary-background: var(--p-surface-100);
  --p-toast-secondary-border-color: var(--p-surface-200);
  --p-toast-secondary-color: var(--p-surface-600);
  --p-toast-secondary-detail-color: var(--p-surface-700);
  --p-toast-secondary-shadow: 0px 4px 8px 0px
    color-mix(in srgb, var(--p-surface-500), transparent 96%);
  --p-toast-secondary-close-button-hover-background: var(--p-surface-200);
  --p-toast-secondary-close-button-focus-ring-color: var(--p-surface-600);
  --p-toast-secondary-close-button-focus-ring-shadow: none;
  --p-toast-contrast-background: var(--p-surface-900);
  --p-toast-contrast-border-color: var(--p-surface-950);
  --p-toast-contrast-color: var(--p-surface-50);
  --p-toast-contrast-detail-color: var(--p-surface-0);
  --p-toast-contrast-shadow: 0px 4px 8px 0px
    color-mix(in srgb, var(--p-surface-950), transparent 96%);
  --p-toast-contrast-close-button-hover-background: var(--p-surface-800);
  --p-toast-contrast-close-button-focus-ring-color: var(--p-surface-50);
  --p-toast-contrast-close-button-focus-ring-shadow: none;
}

.p-dark {
  --p-toast-blur: 10px;
  --p-toast-info-background: color-mix(
    in srgb,
    var(--p-blue-500),
    transparent 84%
  );
  --p-toast-info-border-color: color-mix(
    in srgb,
    var(--p-blue-700),
    transparent 64%
  );
  --p-toast-info-color: var(--p-blue-500);
  --p-toast-info-detail-color: var(--p-surface-0);
  --p-toast-info-shadow: 0px 4px 8px 0px
    color-mix(in srgb, var(--p-blue-500), transparent 96%);
  --p-toast-info-close-button-hover-background: rgba(255, 255, 255, 0.05);
  --p-toast-info-close-button-focus-ring-color: var(--p-blue-500);
  --p-toast-info-close-button-focus-ring-shadow: none;
  --p-toast-success-background: color-mix(
    in srgb,
    var(--p-green-500),
    transparent 84%
  );
  --p-toast-success-border-color: color-mix(
    in srgb,
    var(--p-green-700),
    transparent 64%
  );
  --p-toast-success-color: var(--p-green-500);
  --p-toast-success-detail-color: var(--p-surface-0);
  --p-toast-success-shadow: 0px 4px 8px 0px
    color-mix(in srgb, var(--p-green-500), transparent 96%);
  --p-toast-success-close-button-hover-background: rgba(255, 255, 255, 0.05);
  --p-toast-success-close-button-focus-ring-color: var(--p-green-500);
  --p-toast-success-close-button-focus-ring-shadow: none;
  --p-toast-warn-background: color-mix(
    in srgb,
    var(--p-yellow-500),
    transparent 84%
  );
  --p-toast-warn-border-color: color-mix(
    in srgb,
    var(--p-yellow-700),
    transparent 64%
  );
  --p-toast-warn-color: var(--p-yellow-500);
  --p-toast-warn-detail-color: var(--p-surface-0);
  --p-toast-warn-shadow: 0px 4px 8px 0px
    color-mix(in srgb, var(--p-yellow-500), transparent 96%);
  --p-toast-warn-close-button-hover-background: rgba(255, 255, 255, 0.05);
  --p-toast-warn-close-button-focus-ring-color: var(--p-yellow-500);
  --p-toast-warn-close-button-focus-ring-shadow: none;
  --p-toast-error-background: color-mix(
    in srgb,
    var(--p-red-500),
    transparent 84%
  );
  --p-toast-error-border-color: color-mix(
    in srgb,
    var(--p-red-700),
    transparent 64%
  );
  --p-toast-error-color: var(--p-red-500);
  --p-toast-error-detail-color: var(--p-surface-0);
  --p-toast-error-shadow: 0px 4px 8px 0px
    color-mix(in srgb, var(--p-red-500), transparent 96%);
  --p-toast-error-close-button-hover-background: rgba(255, 255, 255, 0.05);
  --p-toast-error-close-button-focus-ring-color: var(--p-red-500);
  --p-toast-error-close-button-focus-ring-shadow: none;
  --p-toast-secondary-background: var(--p-surface-800);
  --p-toast-secondary-border-color: var(--p-surface-700);
  --p-toast-secondary-color: var(--p-surface-300);
  --p-toast-secondary-detail-color: var(--p-surface-0);
  --p-toast-secondary-shadow: 0px 4px 8px 0px
    color-mix(in srgb, var(--p-surface-500), transparent 96%);
  --p-toast-secondary-close-button-hover-background: var(--p-surface-700);
  --p-toast-secondary-close-button-focus-ring-color: var(--p-surface-300);
  --p-toast-secondary-close-button-focus-ring-shadow: none;
  --p-toast-contrast-background: var(--p-surface-0);
  --p-toast-contrast-border-color: var(--p-surface-100);
  --p-toast-contrast-color: var(--p-surface-950);
  --p-toast-contrast-detail-color: var(--p-surface-950);
  --p-toast-contrast-shadow: 0px 4px 8px 0px
    color-mix(in srgb, var(--p-surface-950), transparent 96%);
  --p-toast-contrast-close-button-hover-background: var(--p-surface-100);
  --p-toast-contrast-close-button-focus-ring-color: var(--p-surface-950);
  --p-toast-contrast-close-button-focus-ring-shadow: none;
}
.p-toast-message-info,
.p-toast-message-success,
.p-toast-message-warn,
.p-toast-message-error,
.p-toast-message-secondary,
.p-toast-message-contrast {
  border-width: var(--p-toast-border-width);
  border-style: solid;
  backdrop-filter: blur(var(--p-toast-blur));
  border-radius: var(--p-toast-border-radius);
}
.p-toast {
  width: 25rem;
  max-width: 90vw;
}

.p-toast-message {
  margin: 0 0 1rem 0;
}

.p-toast-message-content {
  display: flex;
  align-items: flex-start;
  padding: var(--p-toast-content-padding);
  gap: var(--p-toast-content-gap);
}

.p-toast-message-icon {
  flex-shrink: 0;
}

.p-toast-message-text {
  flex: 1;
}

.p-toast-summary {
  font-weight: 700;
  margin-bottom: 0.25rem;
}
.p-toast-close-icon {
  font-size: var(--p-toast-close-icon-size);
  width: var(--p-toast-close-icon-size);
  height: var(--p-toast-close-icon-size);
}
.p-toast-message-info .p-toast-close-button:hover {
  background: var(--p-toast-info-close-button-hover-background);
}
.p-toast-message-warn .p-toast-close-button:hover {
  background: var(--p-toast-warn-close-button-hover-background);
}

.p-toast-message-error .p-toast-close-button:hover {
  background: var(--p-toast-error-close-button-hover-background);
}
.p-toast-close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background: transparent;
  transition:
    background var(--p-toast-transition-duration),
    color var(--p-toast-transition-duration),
    outline-color var(--p-toast-transition-duration),
    box-shadow var(--p-toast-transition-duration);
  outline-color: transparent;
  color: inherit;
  width: var(--p-toast-close-button-width);
  height: var(--p-toast-close-button-height);
  border-radius: var(--p-toast-close-button-border-radius);
  padding: 0;
  border: none;
  user-select: none;
}
.p-icon {
  display: inline-block;
  vertical-align: baseline;
}
.p-toast-detail {
  margin: 0;
}

/* Severity-specific styling */
.p-toast-message-info {
  background: var(--p-toast-info-background);
  border-color: var(--p-toast-info-border-color);
  color: var(--p-toast-info-color);
  box-shadow: var(--p-toast-info-shadow);
}

.p-toast-message-success {
  background: var(--p-toast-success-background);
  border-color: var(--p-toast-success-border-color);
  color: var(--p-toast-success-color);
  box-shadow: var(--p-toast-success-shadow);
}

.p-toast-message-warn {
  background: var(--p-toast-warn-background);
  border-color: var(--p-toast-warn-border-color);
  color: var(--p-toast-warn-color);
  box-shadow: var(--p-toast-warn-shadow);
}

.p-toast-message-error {
  background: var(--p-toast-error-background);
  border-color: var(--p-toast-error-border-color);
  color: var(--p-toast-error-color);
  box-shadow: var(--p-toast-error-shadow);
}

/* Transitions and animations */
.p-toast-message-enter-active {
  transform: translateX(100%);
  transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
}

.p-toast-message-enter-to {
  transform: translateX(0);
}

.p-toast-message-leave-active {
  max-height: 1000px;
  margin-bottom: 0.5rem;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
}

.p-toast-message-leave-to {
  max-height: 0;
  margin-bottom: 0;
  opacity: 0;
  transform: translateX(100%);
}
</style>
