<script setup lang="ts">
import { computed, onMounted, onBeforeUnmount, ref } from "vue";
import type { ToastMessageType } from "~/types/Toast";
import ExclamationTriangleIcon from "@primevue/icons/exclamationtriangle";
import InfoCircleIcon from "@primevue/icons/infocircle";
import TimesCircleIcon from "@primevue/icons/timescircle";
import CheckIcon from "@primevue/icons/check";
import IconXMark from "~/assets/icons/x-mark.svg";
import { ToastStyle } from "./toastStyle";

const props = defineProps<{
  message: ToastMessageType;
  open: boolean;
}>();

const emit = defineEmits<{
  close: [message: ToastMessageType, type: "life-end" | "close"];
  remove: [message: ToastMessageType];
  "update:open": [value: boolean];
}>();

const closeTimeout = ref<NodeJS.Timeout | null>(null);
const createdAt = ref<number | null>(null);
const lifeRemaining = ref<number | null>(null);
const pausedAt = ref<number | null>(null);
const messageClasses = computed(() =>
  ToastStyle.classes.message(props.message.severity),
);
const iconClasses = computed(() =>
  ToastStyle.classes.icon(props.message.severity),
);
const textClasses = computed(() => ToastStyle.classes.text());
const summaryClasses = computed(() => ToastStyle.classes.summary());
const detailClasses = computed(() => ToastStyle.classes.detail());
const closeButtonClasses = computed(() => ToastStyle.classes.closeButton());
const contentMessageClasses = computed(() => ToastStyle.classes.content());
const closeIconClasses = ToastStyle.classes.closeIcon();

const iconComponent = computed(() => {
  const icons = {
    info: InfoCircleIcon,
    success: CheckIcon,
    warn: ExclamationTriangleIcon,
    error: TimesCircleIcon,
  };
  return props.message.severity ? icons[props.message.severity] : null;
});
onMounted(() => {
  if (props.message.life) {
    lifeRemaining.value = props.message.life;
    startTimeout();
  }
});

onBeforeUnmount(() => {
  clearCloseTimeout();
});

function startTimeout() {
  if (!props.message.life) return;
  clearCloseTimeout();
  createdAt.value = Date.now();
  closeTimeout.value = setTimeout(() => {
    emit("close", { message: props.message, type: "life-end" });
  }, lifeRemaining.value!);
}
function pauseTimer() {
  if (!props.message.life) return;

  clearCloseTimeout();

  if (!pausedAt.value && props.message.life) {
    const elapsed = Date.now() - (pausedAt.value ?? Date.now());
    lifeRemaining.value = Math.max(0, props.message.life - elapsed);
  }

  pausedAt.value = Date.now();
}

function onCloseClick() {
  clearCloseTimeout();
  emit("close", { message: props.message, type: "close" });
}

function clearCloseTimeout() {
  if (closeTimeout.value) {
    clearTimeout(closeTimeout.value);
    closeTimeout.value = null;
  }
}

function onOpenChange(open: boolean) {
  emit("update:open", open);
  if (!open) {
    clearCloseTimeout();
    emit("remove", props.message);
  }
}

function onMouseEnter(event: Event) {
  if (props.message.life) {
    pauseTimer();
  }
}

function onMouseLeave(event: Event) {
  if (props.message.life) {
    startTimeout();
  }
}
</script>

<template>
  <ToastRoot :class="[messageClasses]" :duration="message.life" :open="open" @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave" @update:open="onOpenChange">
    <div :class="contentMessageClasses">
      <component :is="iconComponent" :class="iconClasses" aria-hidden="true" />

      <div :class="textClasses">
        <ToastTitle :class="summaryClasses">
          {{ message.summary }}
        </ToastTitle>
        <ToastDescription v-if="message.detail" :class="detailClasses">
          {{ message.detail }}
        </ToastDescription>
      </div>
      <ToastClose :class="closeButtonClasses" @click="onCloseClick">
        <IconXMark :class="closeIconClasses" />
      </ToastClose>
    </div>
  </ToastRoot>
</template>
