import type { UserPreference } from "~/types/User";

export const useUserPreferenceStore = defineStore(
  "userPreferenceStore",
  () => {
    const preference = ref<UserPreference>({ table: {} });
    function setPreference(newPreference: UserPreference) {
      console.table("preference to save", preference);
      preference.value = newPreference;
    }
    function updateTablePreference(tableName: string, newPreference: Object) {
      if (preference.value?.table.hasOwnProperty(tableName)) {
        preference.value.table[tableName] = {
          ...preference.value.table[tableName],
          ...toValue(newPreference),
        };
      } else {
        preference.value.table[tableName] = {
          ...toValue(newPreference),
        };
      }
    }
    function getTablePreference(tableName: string) {
      if (preference.value?.table.hasOwnProperty(tableName)) {
        return preference.value.table[tableName];
      }
      return null;
    }
    return {
      preference,
      setPreference,
      updateTablePreference,
      getTablePreference,
    };
  },
  {
    persist: true,
  },
);
