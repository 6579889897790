import type { FetchContext } from "ofetch";
import type { ConsolaInstance } from "consola";
import { getTenantFromCurrentUrl } from "~/services/tenant";
export default defineAppConfig({
  sanctum: {
    interceptors: {
      onRequest: async (
        app: NuxtApp,
        ctx: FetchContext,
        logger: ConsolaInstance,
      ) => {
        const runtimeConfig = app.$config;
        let tenant = getTenantFromCurrentUrl(runtimeConfig);
        const requestPath = ctx?.request.toString();
        if (requestPath.includes("/login")) {
          tenant = ctx.options.body.tenant;
        } else if (requestPath.includes("api/user") && !tenant) {
          //get it from  shared tenant state
          tenant = app.payload.state.$stenant;
        }
        if (!!tenant) {
          ctx.options.headers.set("x-tenant", tenant);
        }
      },

      onResponse: async (
        app: NuxtApp,
        ctx: FetchContext,
        logger: ConsolaInstance,
      ) => {
        if (
          ctx.request.toString().includes("/login") &&
          ctx.error == undefined
        ) {
          const rolesWithPermissions =
            ctx.response?._data?.rolesWithPermissions;
          const userPreference = JSON.parse(ctx.response?._data?.preference);
          const userPermissions = usePermissions();
          const userRoles = useRoles();
          const userPreferenceStore = useUserPreferenceStore(app.pinia);
          let roles = new Set();
          let permissions = new Set();
          for (let i = 0; i < rolesWithPermissions.length; i++) {
            const roleWithPermissions = rolesWithPermissions[i];
            roles.add(roleWithPermissions.name);
            for (let j = 0; j < roleWithPermissions.permissions.length; j++) {
              permissions.add(roleWithPermissions.permissions[j].name);
            }
          }
          userPermissions.value = Array.from(permissions);
          userRoles.value = Array.from(roles);
          userPreferenceStore.setPreference(userPreference);
        }
      },
    },
  },
});
