import { getWebInstrumentations, initializeFaro } from "@grafana/faro-web-sdk";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  try {
    let instrumentation = [
      ...getWebInstrumentations(),
      new TracingInstrumentation(),
    ];
    const faro = initializeFaro({
      url: config.public.grafanaFaroUrl,
      instrumentations: instrumentation,
      app: {
        name: config.public.grafanaAppName,
        version: config.public.appVersion,
        environment: config.public.appEnvironment,
      },
    });
    nuxtApp.provide("faro", faro);
  } catch (error) {
    console.error("Failed to initialize Faro:", error);
    throw error;
  }
});
