import type { ToastMessageOptions } from "primevue/toast";

interface ToastExtensions {
  id?: number;
  unique?: boolean;
}

export type ToastMessageType = ToastMessageOptions & ToastExtensions;

// Type guard to check if a group is present
export function hasGroup(
  message: ToastMessageType,
): message is ToastMessageType & { group: string } {
  return typeof message.group === "string" && message.group !== "";
}
