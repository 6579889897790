<template>
  <Toast :position="'top-right'" :group="group">
    <slot />
  </Toast>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Toast from "./Toast.vue";
const group = ref<string | undefined>(undefined);
</script>
